import { useMutation, useQuery } from "@apollo/client";
import { PencilIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import { omit } from "lodash";
import { useCallback, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { RxReset } from "react-icons/rx";
import {
  GetMyWorkHistoryQuery,
  GetMyWorkHistoryQueryVariables,
  MyWorkHistoryItemFragmentFragment,
  UpdateMyWorkHistoryMutation,
  UpdateMyWorkHistoryMutationVariables,
  WorkHistoryGoalUpdateFragmentFragment,
  WorkHistoryRecognitionFragmentFragment,
  WorkHistorySummaryInput,
  WorkHistorySummarySectionNode,
} from "types/graphql-schema";

import useLabel from "@apps/use-label/use-label";
import { currentUserVar } from "@cache/cache";
import Button from "@components/button/button";
import Input from "@components/input/input";
import Loading from "@components/loading/loading";
import TextareaAutosize from "@components/textarea-autosize/textarea-autosize";
import Tooltip from "@components/tooltip/tooltip";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import { assertEdgesNonNull, assertNonNull } from "@helpers/helpers";

import getMyWorkHistoryQuery from "../graphql/get-my-work-history-query";
import updateWorkHistoryMutation from "../graphql/update-work-history-mutation";
import ExternalEventObject, {
  WorkHistoryExternalEventObject,
} from "./work-history-external-object";
import WorkHistoryGoalUpdate from "./work-history-goal-update";
import WorkHistoryRecognition from "./work-history-recognition";
import WorkHistorySummaryHeader from "./work-history-summary-header";

type MyWorkHistorySection = Pick<
  WorkHistorySummarySectionNode,
  "id" | "title" | "content" | "hidden"
> & { resetAvailable: boolean };

const WorkHistorySidebar = ({
  userId,
  editable,
}: {
  userId?: number;
  editable?: boolean;
}) => {
  const currentUser = currentUserVar();
  const label = useLabel();
  const [summary, setSummary] = useState<string>("");
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [isEditingSectionIndex, setIsEditingSectionIndex] = useState<
    number | null
  >(null);
  const [summaryResetAvailable, setSummaryResetAvailable] = useState(false);
  const [workHistoryItem, setWorkHistoryItem] =
    useState<MyWorkHistoryItemFragmentFragment | null>(null);
  const [workHistorySections, setWorkHistorySections] = useState<
    MyWorkHistorySection[]
  >([]);
  const [externalEventData, setExternalEventData] = useState<
    WorkHistoryExternalEventObject[]
  >([]);
  const [goalUpdateData, setGoalUpdateData] = useState<
    WorkHistoryGoalUpdateFragmentFragment[]
  >([]);
  const [recognitionData, setRecognitionData] = useState<
    WorkHistoryRecognitionFragmentFragment[]
  >([]);
  const [expanded, setExpanded] = useState(false);

  const updateWorkHistoryLocalData = useCallback(
    (newWorkHistoryItem?: MyWorkHistoryItemFragmentFragment | null) => {
      if (newWorkHistoryItem) {
        const sections = assertEdgesNonNull(newWorkHistoryItem.sections).map(
          (section) => ({
            id: section.id,
            title: section.modifiedTitle || section.title,
            content: section.modifiedContent || section.content,
            hidden: section.hidden,
            resetAvailable:
              !!section.modifiedTitle || !!section.modifiedContent,
          })
        );

        setWorkHistoryItem(newWorkHistoryItem);
        setSummary(
          newWorkHistoryItem.modifiedSummary || newWorkHistoryItem.summary
        );
        setSummaryResetAvailable(!!newWorkHistoryItem.modifiedSummary);
        setWorkHistorySections(sections);
        const goalUpdates = newWorkHistoryItem.goalUpdates.edges
          .map((edge) => assertNonNull(edge?.node))
          .filter((goalUpdate) => goalUpdate.goal);
        setGoalUpdateData(goalUpdates);
        setRecognitionData(assertEdgesNonNull(newWorkHistoryItem.recognitions));

        try {
          const externalEventObjects = JSON.parse(
            newWorkHistoryItem.externalEventData
          ) as WorkHistoryExternalEventObject[];
          setExternalEventData(externalEventObjects);
        } catch (e) {
          captureException(e);
        }
      }
    },
    []
  );

  const { loading } = useQuery<
    GetMyWorkHistoryQuery,
    GetMyWorkHistoryQueryVariables
  >(getMyWorkHistoryQuery, {
    variables: {
      userId: userId ?? currentUser.id,
    },
    onError: onNotificationErrorHandler(),
    onCompleted: (data) => {
      const workHistoryItem = data?.workHistory?.edges?.[0]?.node;
      updateWorkHistoryLocalData(workHistoryItem);
    },
  });

  const [saveWorkHistory, { loading: isSavingWorkHistory }] = useMutation<
    UpdateMyWorkHistoryMutation,
    UpdateMyWorkHistoryMutationVariables
  >(updateWorkHistoryMutation);

  const handleUpdateWorkHistory = useCallback(
    (workHistoryData: WorkHistorySummaryInput) => {
      if (!workHistoryItem) {
        return;
      }

      saveWorkHistory({
        variables: {
          id: workHistoryItem.id,
          data: workHistoryData,
        },
        onCompleted: (data) => {
          const workHistoryItem = data?.updateWorkHistory?.workHistorySummary;
          updateWorkHistoryLocalData(workHistoryItem);
          setIsEditingSummary(false);
          setIsEditingSectionIndex(null);
        },
        onError: onNotificationErrorHandler(),
      });
    },
    [saveWorkHistory, updateWorkHistoryLocalData, workHistoryItem]
  );

  const handleResetSummaryToOriginal = useCallback(() => {
    handleUpdateWorkHistory({
      // use blank string to reset to original
      summary: "",
    });
  }, [handleUpdateWorkHistory]);

  const handleCancelSummaryEdit = useCallback(() => {
    if (!workHistoryItem) {
      return;
    }

    setSummary(workHistoryItem.modifiedSummary || workHistoryItem.summary);
    setIsEditingSummary(false);
  }, [workHistoryItem]);

  const handleSaveSummaryEdit = useCallback(() => {
    handleUpdateWorkHistory({
      summary: summary,
    });
  }, [handleUpdateWorkHistory, summary]);

  const handleChangeSectionVisibility = useCallback(
    (sectionId: number, hidden: boolean) => {
      const existingSection = workHistorySections.find(
        (section) => section.id === sectionId
      );
      if (!existingSection) {
        return;
      }

      handleUpdateWorkHistory({
        sections: [{ ...omit(existingSection, "resetAvailable"), hidden }],
      });
    },
    [handleUpdateWorkHistory, workHistorySections]
  );

  const handleResetSectionToOriginal = useCallback(
    (sectionId: number) => {
      const existingSection = workHistorySections.find(
        (section) => section.id === sectionId
      );
      if (!existingSection) {
        return;
      }

      handleUpdateWorkHistory({
        sections: [
          {
            ...omit(existingSection, "resetAvailable"),
            title: "",
            content: "",
          },
        ],
      });
    },
    [handleUpdateWorkHistory, workHistorySections]
  );

  const handleUpdateSection = useCallback(
    (sectionIndex: number, data: { title?: string; content?: string }) => {
      const existingSection = workHistorySections[sectionIndex];
      if (!existingSection) {
        return;
      }

      setWorkHistorySections(
        workHistorySections.map((section, index) => {
          if (index === sectionIndex) {
            return {
              ...section,
              ...data,
            };
          }
          return section;
        })
      );
    },
    [workHistorySections]
  );

  const handleSaveSection = useCallback(
    (sectionIndex: number) => {
      const existingSection = workHistorySections[sectionIndex];
      if (!existingSection) {
        return;
      }

      handleUpdateWorkHistory({
        sections: [{ ...omit(existingSection, "resetAvailable") }],
      });
    },
    [handleUpdateWorkHistory, workHistorySections]
  );

  const handleCancelSectionEdit = useCallback(() => {
    if (!workHistoryItem) {
      return;
    }
    setIsEditingSectionIndex(null);
    setWorkHistorySections(
      assertEdgesNonNull(workHistoryItem.sections).map((section) => ({
        id: section.id,
        title: section.modifiedTitle || section.title,
        content: section.modifiedContent || section.content,
        hidden: section.hidden,
        resetAvailable: !!section.modifiedTitle || !!section.modifiedContent,
      }))
    );
  }, [workHistoryItem]);

  if (loading) {
    return (
      <div className="px-8 py-4 w-full mx-auto">
        <Loading />
      </div>
    );
  }

  if (!workHistoryItem) {
    return (
      <div className="px-8 py-4 w-full text-center text-sm text-gray-500">
        No work history summary available. Please check back later.
      </div>
    );
  }

  return (
    <div className="w-full">
      <WorkHistorySummaryHeader workHistoryItem={workHistoryItem} />

      <div className="px-8 py-4 text-sm border-b ">
        <div className="font-medium mb-2">Time spent in meetings</div>
        <div className="text-gray-500">
          {`${workHistoryItem.meetingTimeHours} hours (${workHistoryItem.meetingCount} meetings)`}
        </div>
      </div>

      <div className="px-8 py-4 text-sm border-b last:border-b-0 relative">
        {!isEditingSummary && isEditingSectionIndex === null && editable && (
          <div className="top-2 right-2 absolute flex items-center gap-2">
            {summaryResetAvailable && (
              <Tooltip delay={100} text="Reset to original">
                <button
                  className="text-gray-500 p-2 rounded hover:bg-gray-100"
                  onClick={handleResetSummaryToOriginal}
                >
                  <RxReset className="w-4 h-4" />
                </button>
              </Tooltip>
            )}
            <Tooltip delay={100} text="Edit">
              <button
                className="text-gray-500 p-2 rounded hover:bg-gray-100"
                onClick={() => setIsEditingSummary(true)}
              >
                <PencilIcon className="w-4 h-4" />
              </button>
            </Tooltip>
          </div>
        )}
        <div className="font-medium mb-2">Summary</div>
        {isEditingSummary ? (
          <>
            <TextareaAutosize
              className="w-full border rounded-md p-2 resize-none"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
            <div className="flex items-center justify-end gap-2">
              <Button
                type="button"
                small
                text="Cancel"
                onClick={handleCancelSummaryEdit}
              />
              <Button
                type="button"
                small
                text="Save"
                theme="primary"
                disabled={isSavingWorkHistory}
                onClick={handleSaveSummaryEdit}
              />
            </div>
          </>
        ) : (
          <div className="whitespace-pre-line text-gray-500">{summary}</div>
        )}
      </div>

      {!expanded && (
        <div
          className="text-center p-4 hover:bg-gray-200 hover:cursor text-gray-500 text-sm"
          role="button"
          onClick={() => setExpanded(true)}
        >
          View more
        </div>
      )}

      {expanded && goalUpdateData.length > 0 && (
        <div className="px-8 py-4 text-sm border-b">
          <div className="font-medium mb-4">
            {label("goal", { pluralize: true, capitalize: true })}
          </div>
          <div className="flex flex-col gap-2">
            {goalUpdateData.map((goalUpdate) => (
              <WorkHistoryGoalUpdate
                key={assertNonNull(goalUpdate.goal).id}
                updateData={goalUpdate}
              />
            ))}
          </div>
        </div>
      )}

      {expanded && recognitionData.length > 0 && (
        <div className="px-8 py-4 text-sm border-b">
          <div className="font-medium mb-4">
            {label("recognition", { pluralize: true, capitalize: true })}
          </div>
          <div className="flex flex-col gap-2">
            {recognitionData.map((recognition) => (
              <WorkHistoryRecognition
                key={recognition.id}
                recognition={recognition}
              />
            ))}
          </div>
        </div>
      )}

      {expanded &&
        workHistorySections.map((section, index) => (
          <div
            key={section.id}
            className={classNames(
              "px-8 py-4 text-sm border-b last:border-b-0 relative",
              section.hidden && "bg-gray-200"
            )}
          >
            {!isEditingSummary && isEditingSectionIndex === null && editable && (
              <div className="top-2 right-2 absolute flex items-center gap-2">
                {section.resetAvailable && !section.hidden && (
                  <Tooltip delay={100} text="Reset to original">
                    <button
                      className="text-gray-500 p-2 rounded hover:bg-gray-100"
                      onClick={() => handleResetSectionToOriginal(section.id)}
                    >
                      <RxReset className="w-4 h-4" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip delay={100} text={section.hidden ? "Show" : "Hide"}>
                  <button
                    className="text-gray-500 p-2 rounded hover:bg-gray-100"
                    onClick={() =>
                      handleChangeSectionVisibility(section.id, !section.hidden)
                    }
                  >
                    {section.hidden ? (
                      <FaEye className="w-4 h-4" />
                    ) : (
                      <FaEyeSlash className="w-4 h-4" />
                    )}
                  </button>
                </Tooltip>
                {!section.hidden && (
                  <Tooltip delay={100} text="Edit">
                    <button
                      className="text-gray-500 p-2 rounded hover:bg-gray-100"
                      onClick={() => setIsEditingSectionIndex(index)}
                    >
                      <PencilIcon className="w-4 h-4" />
                    </button>
                  </Tooltip>
                )}
              </div>
            )}
            {isEditingSectionIndex === index ? (
              <>
                <Input
                  value={section.title}
                  onChange={(e) => {
                    const newTitle = e.target.value;
                    handleUpdateSection(index, { title: newTitle });
                  }}
                />
                <TextareaAutosize
                  className="mt-1 w-full border rounded-md p-2 resize-none"
                  value={section.content}
                  onChange={(e) => {
                    const newContent = e.target.value;
                    handleUpdateSection(index, { content: newContent });
                  }}
                />
                <div className="flex items-center justify-end gap-2">
                  <Button
                    type="button"
                    small
                    text="Cancel"
                    onClick={handleCancelSectionEdit}
                  />
                  <Button
                    type="button"
                    small
                    text="Save"
                    theme="primary"
                    disabled={isSavingWorkHistory}
                    onClick={() => handleSaveSection(index)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="font-medium mb-2">{section.title}</div>
                <div className="whitespace-pre-line text-gray-500">
                  {section.content}
                </div>
              </>
            )}
          </div>
        ))}

      {expanded && externalEventData.length > 0 && (
        <div className="px-8 py-4 text-sm border-b">
          <div className="font-medium mb-4">From integrations:</div>
          <div className="flex flex-col gap-2">
            {externalEventData.map(
              (eventObject: WorkHistoryExternalEventObject) => (
                <ExternalEventObject
                  key={eventObject.id}
                  eventObject={eventObject}
                />
              )
            )}
          </div>
        </div>
      )}

      {expanded && (
        <div
          className="text-center p-4 hover:bg-gray-200 hover:cursor text-gray-500 text-sm"
          role="button"
          onClick={() => setExpanded(false)}
        >
          View less
        </div>
      )}
    </div>
  );
};

export default WorkHistorySidebar;
