import { gql } from "@apollo/client";

const WorkHistoryRecognitionFragment = gql`
  fragment WorkHistoryRecognitionFragment on RecognitionArtifactNode {
    id
    title
    creator {
      id
      name
    }
    recognitionCoreValue {
      id
      emoji
      image
    }
  }
`;

export default WorkHistoryRecognitionFragment;
