import { Link, useLocation } from "react-router-dom";
import {
  ArtifactType,
  RecognitionArtifactNode,
  RecognitionCoreValueNode,
  UserNode,
} from "types/graphql-schema";
import { TFLocationState } from "types/topicflow";

import RecognitionCoreValueIcon from "@apps/artifact/components/recognition-core-value-icon";
import { classNames } from "@helpers/css";
import { getUrl, toWithBackground } from "@helpers/helpers";

const WorkHistoryRecognition = ({
  recognition,
}: {
  recognition: Pick<RecognitionArtifactNode, "id" | "title"> & {
    recognitionCoreValue?: Pick<
      RecognitionCoreValueNode,
      "emoji" | "image"
    > | null;
    creator?: Pick<UserNode, "id" | "name"> | null;
  };
}) => {
  const location = useLocation<TFLocationState>();

  const artifactUrl = getUrl({
    artifactId: recognition.id,
    artifactType: ArtifactType.Goal,
  });

  return (
    <div className="flex items-center gap-2">
      <div>
        <RecognitionCoreValueIcon artifact={recognition} />
      </div>
      <div>
        <div>
          <Link
            className={classNames("flex-1 break-word group line-clamp-2")}
            to={toWithBackground({
              pathname: artifactUrl,
              location,
            })}
          >
            <span className="font-medium no-underline group-hover:underline-offset-2 group-hover:underline group-hover:text-blue-600 group-hover:decoration-blue-300">
              {recognition.title}
            </span>
          </Link>
        </div>
        <div className="text-xs text-gray-500">
          {recognition.creator && `Received from ${recognition.creator.name}`}
        </div>
      </div>
    </div>
  );
};

export default WorkHistoryRecognition;
